<template>
  <div class="corpo">
    <img class="topo" src="../assets/images/museu-logo.png" />
    <!-- <div class="grid2">
        <div>
            <img src="../assets/images/museu-arte1.jpeg" />
        </div>
        <div>
            <img src="../assets/images/museu-arte2.jpeg" />
        </div>
    </div> -->
    <div class="texto-w1">
      PARA TER UMA EXPERIÊNCIA<br />
      BACANA NO NOSSO MUSEU,<br />
      CONFIRA:
    </div>

    <div class="texto-w2 line">
      <div class="title">REGRAS DE VISITAÇÃO</div>
      <span class="y">•</span> Dentro do museu, a visita em grupo tem limite de
      20 pessoas.<br />
      <span class="y">•</span> Visita guiada e com duração de 30 minutos!<br />
      <span class="y">•</span> Escola/Instituição com mais 20 pessoas terão
      que<br />aguardar no ônibus ou na área externa, até que as salas<br />sejam
      liberadas para entrar.
    </div>
    <div class="texto-w2 line">
      <div class="title">NO INTERIOR DO MUSEU</div>
      <span class="y">•</span> Proibido tocar nas peças/equipamentos.<br />
      <span class="y">•</span> Proibido consumir alimentos e bebidas.<br />
      <span class="y">•</span> Proibido correr dentro das salas.<br />
      <span class="y">•</span> Proibido subir nos mostruários.
    </div>

    <div class="texto-w2">
      <span class="title">Importante:</span> Se danificar alguma peça ou
      equipamento, o<br />ressarcimento é responsabilidade do visitante e/ou
      da<br />pessoa responsável pelo grupo ou instituição.
    </div>

    <div class="texto-w2 contato">
      Rua Cândido Mariano, n° 462 - Centro-Aquidauana-MS
      <div class="agendar">
        <a
          class="btn"
          href="https://forms.gle/SAJ4QUjpLgrjmBSn6"
          target="_black"
          >Agendar</a
        >
        <img class="seta" src="../assets/images/seta.png" />
      </div>
    </div>
    <!-- <div class="grid">
      <div class="endereco">
        <div class="end">
          Rua Cândido Mariano, n° 462<br />
          Centro-Aquidauana-MS
        </div>
        <div class="horario">
          <h2>HORÁRIO DE FUNCIONAMENTO</h2>
          Segunda a sexta-feira: 8h às 18h
        </div>
        <div class="ma">Visitas aos sábados precisam ser agendadas antecipadamente.</div>
        <div class="agendar"><a class="btn" href="https://forms.gle/SAJ4QUjpLgrjmBSn6" target="_black">Agendar</a></div>
      </div>

      <div class="agendamento">
        <div>Visitas em grupo para mais de 20 pessoas (escolas, instituições e entidades), deverão ser
agendadas com antecedência pelo telefone.</div>
        <div class="ma">A entrada no Museu é gratuita.</div>
        <div class="ag">
          <div class="ti">AGENDAMENTO</div>
          <div><h2>(67) 3240-1400</h2></div>
          <div>(Ramais 1572 ou 1573)</div>
        </div>
      </div> -->

    <!--  </div> -->
    <div class="flex">
      <div>
        <img src="../assets/images/prefeitura-logo.png" />
      </div>
      <div>
        <img src="../assets/images/brasao-logo.png" />
      </div>
      <div>
        <img src="../assets/images/sectur-logo.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.topo {
  padding-top: 40px;
  padding-bottom: 20px;
  width: 60%;
}

.texto-w1 {
  color: #f2ca30;
  font-size: 40pt;
  font-family: "BreveNewsBlack";
  line-height: 40pt;
  padding-top: 20px;
  padding-bottom: 20px;
}

.texto-w2 {
  color: #fff;
  font-family: "BreveNews";
  font-size: 16pt;
  padding-top: 10px;
  padding-bottom: 10px;

  .title {
    color: #f2ca30;
    font-family: "BreveNewsBlack";
    font-size: 20pt;
  }
  .y {
    color: #f2ca30;
  }
}

.seta {
  height: 30px;
  position: relative;
  top: 35px;
  right: 60px;
}

.line {
  border: 1px solid #f2ca30;
  max-width: 660px;
  border-radius: 10px;
  margin: 10px auto;
  margin-top: 40px;
  margin-bottom: 40px;
}
.ma {
  margin-top: 15px;
}

.contato {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 3px;
  margin: 0 20%;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 3px;
  margin: 0 20%;
  img {
    width: 100%;
  }
}

.agendar {
  padding-left: 40px;
  .btn {
    background-color: #f2ca30;
    color: #1f367f;
    padding-left: 35px;
    padding-right: 35px;
    font-family: "BreveNewsBlack";
    font-size: 16pt;
  }
}
.corpo {
  background: rgb(4, 81, 166);
  background: radial-gradient(
    circle,
    rgba(4, 81, 166, 1) 0%,
    rgba(0, 39, 116, 1) 100%
  );
  color: var(--background-color-secondary);
  font-size: 16px;
  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  .endereco,
  .agendamento {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    .horario {
      h2 {
        font-size: 18px !important;
        margin: 0;
      }
    }
    .ag {
      .ti {
        font-size: 18px !important;
        margin-bottom: 0;
        margin-top: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
      }
    }
    .end {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 936px) {
  .topo {
    width: 100%;
  }
  .grid {
    margin: 0;
    grid-template-columns: repeat(1, 1fr);
  }
  .texto-w1 {
    font-size: 30pt;
    line-height: 40pt;
  }
  .texto-w2 {
    font-size: 14pt;
    .title {
      font-size: 16pt;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    div {
      margin-top: 20px;
    }
  }
  .agendamento {
    .horario {
      h2 {
        font-size: 14px !important;
      }
    }
    .ag {
      .ti {
        font-size: 14px !important;
      }
    }
  }
}
</style>
